<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-card-title>Turn servers</v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$can('create', 'settings_multi')"
          :color="Pallette.actionButtons.newItem"
          fab
          dark
          absolute
          bottom
          right
          :small="!$vuetify.breakpoint.smAndUp"
          @click.stop="createTurnServer()"
          data-testid="btn-create-turn-server"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <TurnServersDialogCrud
          :dialog.sync="dialog"
          :editedIndex="editedIndex"
          :activeItem="activeItem"
          @turn-server-data-saved="fetchTableData"
          ref="refDialogTurnServer"
        ></TurnServersDialogCrud>
      </v-toolbar>

      <v-card-text>
        <DataTableExtended
          ref="table"
          :calculate-widths="true"
          :headers="headers"
          :items="turnServersParsed"
          :server-items-length="turnServersCount"
          class="elevation-0 border"
          :loading="showTableLoader"
          :no-data-text="showTableLoader ? 'Loading…' : 'No data available'"
          v-on:init-table-data="fetchTableData"
          :customSearch="filterSearch"
          @update-search="
            (newS) => {
              this.filterSearch = newS
            }
          "
          data-testid="table-turn-servers"
        >
          <template v-slot:top="{ options, updateOptions }">
            <v-row class="px-3 pt-3">
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  v-model="filterSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  :style="$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''"
                  clearable
                  data-testid="filter-search"
                />
              </v-col>
            </v-row>
            <SortMobile
              :headers="headers"
              :options="options"
              @update:options="updateOptions"
              v-if="!$vuetify.breakpoint.smAndUp"
            />
          </template>

          <template v-slot:[`item.data.name`]="{ item }">
            <div style="min-width: 120px" data-testid="turn-name">
              {{ item.data.name }}
            </div>
          </template>
          <template v-slot:[`item.data.hostname`]="{ item }">
            <div data-testid="turn-url">
              {{ item.data.url }}
            </div>
          </template>
          <template v-slot:[`item.data.balancing_group`]="{ item }">
            <div style="min-width: 120px" data-testid="turn-balancing-group">
              {{ item.data.balancing_group }}
            </div>
          </template>
          <template v-slot:[`item.data.real_ip`]="{ item }">
            <div data-testid="turn-real-ip">
              {{ item.data.real_ip }}
            </div>
          </template>
          <template v-slot:[`item.menu`]="{ item }">
            <v-menu transition="slide-y-transition" left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  x-small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :color="Pallette.actionButtons.menu"
                  class="clear-padding"
                  data-testid="btn-turn-actions-more"
                  v-if="
                    $can('update', 'settings_multi') ||
                    $can('delete', 'settings_multi') ||
                    $can('update', 'Superadmin') ||
                    $can('delete', 'Superadmin')
                  "
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  @click="editTurnServer(item)"
                  v-if="
                    $can('update', 'settings_multi') ||
                    $can('update', 'Superadmin')
                  "
                  data-testid="btn-edit-turn-server"
                >
                  <v-list-item-content>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.edit"
                      >mdi-pencil</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  @click="deleteTurnServer(item)"
                  v-if="
                    $can('delete', 'settings_multi') ||
                    $can('delete', 'Superadmin')
                  "
                  data-testid="btn-delete-turn-server"
                >
                  <v-list-item-content>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.delete">
                      mdi-delete</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template
            v-slot:expanded-item="{ headers, item }"
            v-if="
              !$vuetify.breakpoint.smAndUp &&
              ($can('delete', 'settings_multi') ||
                $can('update', 'settings_multi') ||
                $can('update', 'Superadmin'))
            "
          >
            <td :colspan="headers.length">
              <v-row align="center" justify="space-around">
                <VerticalButton
                  @click.native="editTurnServer(item)"
                  v-if="
                    $can('update', 'settings_multi') ||
                    $can('update', 'Superadmin')
                  "
                  icon="mdi-pencil"
                  text="Edit"
                  :color="Pallette.actionButtons.edit"
                  data-testid="btn-edit-turn-server"
                />
                <VerticalButton
                  @click.native="deleteTurnServer(item)"
                  v-if="
                    $can('delete', 'settings_multi') ||
                    $can('delete', 'Superadmin')
                  "
                  icon="mdi-delete"
                  text="Delete"
                  :color="Pallette.actionButtons.delete"
                  data-testid="btn-delete-turn-server"
                />
              </v-row>
            </td>
          </template>
        </DataTableExtended>
      </v-card-text>
    </v-card>

    <Confirm ref="confirmDialog"></Confirm>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { cloneDeep } from "lodash"
import TurnServersDialogCrud from "./TurnServersDialogCrud"
import Confirm from "@/components/Confirm"
import BackButtonMixin from "@/mixins/close-modal-on-backbutton.js"
import VerticalButton from "@/components/buttons/VerticalButton"
import DataTableExtended from "@/components/table/DataTableExtended"
import SortMobile from "@/components/table/SortMobile"

export default {
  components: {
    TurnServersDialogCrud,
    Confirm,
    VerticalButton,
    DataTableExtended,
    SortMobile,
  },

  mixins: [BackButtonMixin],

  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "Name",
          align: "left",
          value: "data.name",
          sortable: true,
        },
        {
          text: "Url",
          align: "left",
          value: "data.hostname",
          sortable: true,
        },
        {
          text: "Group",
          align: "left",
          value: "data.balancing_group",
          sortable: true,
        },
        {
          text: "IP",
          align: "left",
          value: "data.real_ip",
          sortable: true,
        },
        {
          text: "",
          value: "menu",
          align: "center",
          sortable: false,
          class: "th-clear-padding",
          width: 50,
        },
      ],
      turnServers: [],
      turnServersCount: 0,
      editedIndex: -1,
      activeItem: {
        name: "",
        protocol: "",
        hostname: "",
        port: "",
        is_active: true,
        real_ip: "",
        balancing_group: null,
      },
      defaultItem: {
        name: "",
        protocol: "",
        hostname: "",
        port: "",
        is_active: true,
        real_ip: "",
        balancing_group: null,
      },
      loading: false,
      filterSearch: "",
    }
  },

  mounted: function () {
    if (!this.$can("read", "settings_multi")) {
      if (!this.$can("read", "Superadmin"))
        this.$router.push({ name: "home" }).catch((err) => {})
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    filterSearch() {
      this.fetchTableData()
    },
  },

  methods: {
    fetchTableData: function () {
      let self = this
      self.$store.dispatch("saveTableLoaderAction", true)
      let getParams = self.$refs.table.getTableServerParams()
      getParams.type = "turn_server"
      if (self.filterSearch !== "") getParams.search = self.filterSearch
      self.$store
        .dispatch("turnServersCompanyGetLists", { params: getParams })
        .then(function (response) {
          self.turnServers = response?.data?.data?.settings_multi || []
          self.turnServersCount = response?.data?.data?.full_count || []
          self.$store.dispatch("saveTableLoaderAction", false)
        })
    },
    createTurnServer() {
      this.dialog = true
      this.activeItem = cloneDeep(this.defaultItem)
      this.editedIndex = -1
    },
    editTurnServer(item) {
      if (
        this.$can("update", "settings_multi") ||
        this.$can("update", "Superadmin")
      ) {
        this.dialog = true
        this.activeItem = cloneDeep(item.data)
        this.editedIndex = item._key
      }
    },
    deleteTurnServer(item) {
      let self = this
      self.$refs.confirmDialog
        .open("Delete", "Are you sure you want to delete this turn server?", {
          color: "red darken-4",
        })
        .then((confirm) => {
          if (confirm) {
            self.$refs.refDialogTurnServer.deleteTurnServer(item)
          }
        })
    },
    close() {
      this.dialog = false
      this.editedIndex = -1
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
    }),
    getCompleteUrl(item) {
      return (item) => {
        return item.protocol + "://" + item.hostname + ":" + item.port
      }
    },
    turnServersParsed() {
      let self = this
      let turns = self.turnServers
      let result = turns.map((val) => {
        val.data.url = self.getCompleteUrl(val.data)
        return val
      })
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.tr-pointer {
  cursor: pointer;
}
</style>
