<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-form data-vv-scope="form-turn-servers" data-testid="form-turn-server">
      <v-card>
        <v-card-title>
          <span class="headline" v-html="getTitle()"></span>
          <v-spacer />
          <v-switch
            v-model="activeItem.is_active"
            label="Active"
            input-value="true"
            data-vv-validate-on="change"
            data-testid="switch-turn-is-active"
          ></v-switch>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="3">
                <v-select
                  outlined
                  v-model="activeItem.protocol"
                  :items="protocols_list"
                  item-text="name"
                  item-value="pk"
                  :menu-props="{ maxHeight: '400' }"
                  label="Protocol"
                  hint="Url protocol"
                  persistent-hint
                  :error-messages="errors.collect('form-turn-servers.protocol')"
                  data-vv-name="protocol"
                  v-validate="'required'"
                  data-vv-validate-on="change"
                  data-testid="protocol"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  label="Hostname"
                  v-model="activeItem.hostname"
                  :error-messages="errors.collect('form-turn-servers.hostname')"
                  v-validate="{
                    required: true,
                    regex: /^((?!-)[A-Za-z0-9-]{1,63}(?!-)\.)+[A-Za-z]{2,6}$/,
                  }"
                  data-vv-name="hostname"
                  data-vv-validate-on="change"
                  data-testid="hostname"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  label="Port"
                  v-model="activeItem.port"
                  :error-messages="errors.collect('form-turn-servers.port')"
                  v-validate="{ required: true, max: 5, min_value: 1 }"
                  data-vv-name="port"
                  data-vv-validate-on="change"
                  data-testid="port"
                />
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  outlined
                  label="Name"
                  v-model="activeItem.name"
                  :error-messages="errors.collect('form-turn-servers.name')"
                  v-validate="{
                    required: true,
                    max: 25,
                    regex: /^[^~$%^{}<>]*$/,
                  }"
                  data-vv-name="name"
                  data-vv-validate-on="change"
                  data-testid="name"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Real IP"
                  v-model="activeItem.real_ip"
                  :error-messages="errors.collect('form-turn-servers.real_ip')"
                  data-vv-name="real_ip"
                  data-vv-validate-on="change"
                  v-validate="{
                    required: true,
                    max: 25,
                    regex:
                      /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/g,
                  }"
                  data-testid="real-ip"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  outlined
                  v-model="activeItem.balancing_group"
                  :items="balancing_groups_list"
                  :menu-props="{ maxHeight: '400' }"
                  label="Balancing group"
                  :error-messages="
                    errors.collect('form-turn-servers.balancing_group')
                  "
                  data-vv-name="balancing_group"
                  v-validate="'required'"
                  data-vv-validate-on="change"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="dialogBalancingGroup = true"
                  data-testid="balancing-group"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click.native="closeDialog()"
            data-testid="btn-close"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="save()"
            data-testid="btn-save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="snackbar.y === 'bottom'"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn color="pink" text @click="snackbar.show = false"> Close </v-btn>
    </v-snackbar>

    <BalancingGroupDialog
      :dialog.sync="dialogBalancingGroup"
      @save-balancing-group="saveBalancingGroup"
    ></BalancingGroupDialog>
  </v-dialog>
</template>

<script>
import BalancingGroupDialog from "../BalancingGroupDialog"

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    activeItem: {
      type: Object,
      default() {
        return {}
      },
    },
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },
  components: {
    BalancingGroupDialog,
  },
  data() {
    return {
      protocols_list: [
        { pk: "https", name: "https" },
        { pk: "http", name: "http" },
      ],
      snackbar: {
        show: false,
        y: "top",
        x: null,
        mode: "",
        timeout: 3000,
        text: "Profile succesfully saved.",
        color: "success",
      },
      balancing_groups_list: [],
      dialogBalancingGroup: false,
    }
  },
  watch: {
    dialog: function (newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.getBalancingGroupList()
      }
    },
  },
  methods: {
    getTitle() {
      return this.editedIndex == -1
        ? "Add new turn server"
        : `<span class="blue-grey--text text--lighten-4">Edit turn server</span> ${this.activeItem.name}`
    },

    createTurnServer(data) {
      let app = this
      app.$validator.errors.clear("form-turn-servers")
      app.$validator.validateAll("form-turn-servers").then((result) => {
        if (result) {
          app.$store
            .dispatch("turnServersCreateAction", data)
            .then(({ data }) => {
              app.$emit("turn-server-data-saved")
              app.closeDialog()
            })
            .catch(({ response }) => {
              console.log("eeerrrrorrr", response)
              app.handleValidationErrors(response)
            })
        }
      })
    },

    editTurnServer(data) {
      let app = this
      app.$validator.errors.clear("form-turn-servers")
      app.$validator.validateAll("form-turn-servers").then((result) => {
        // console.log('result--', result);
        if (result) {
          app.$store
            .dispatch("turnServersEditAction", data)
            .then(({ data }) => {
              app.$emit("turn-server-data-saved")
              app.closeDialog()
              console.log("succesfull edit engine", data)
            })
            .catch(({ response }) => {
              //app.handleValidationErrors(response);
              console.log("error on editTurnServer")
            })
        }
      })
    },

    save() {
      let app = this
      let formData = {
        data: {
          type: "turn_server",
          data: {
            name: app.activeItem.name,
            protocol: app.activeItem.protocol,
            hostname: app.activeItem.hostname,
            port: app.activeItem.port,
            is_active: app.activeItem.is_active,
            real_ip: app.activeItem.real_ip,
            balancing_group: app.activeItem.balancing_group,
          },
        },
      }

      if (this.editedIndex > -1) {
        formData["slug"] = this.editedIndex
        app.editTurnServer(formData)
      } else {
        app.createTurnServer(formData)
      }
    },

    deleteTurnServer(item) {
      let app = this
      app.$store.dispatch("saveTableLoaderAction", true)
      app.$store.dispatch("turnServersDeleteAction", item).then(({ data }) => {
        app.$emit("turn-server-data-saved")
        app.$store.dispatch("saveTableLoaderAction", false)
      })
    },

    closeDialog() {
      let app = this
      this.$emit("update:dialog", false)
      app.$validator.errors.clear("form-turn-servers")
    },

    toggleAll() {
      console.log(this.engines.slice())
      if (this.selectedEngines.length) this.selectedEngines = []
      else this.selectedEngines = this.engines.slice()
    },

    handleValidationErrors(response) {
      let self = this
      response.data.forEach((val, key) => {
        self.$validator.errors.add({
          scope: "form-turn-servers",
          field: key,
          msg: val,
        })
      })
    },

    getBalancingGroupList() {
      let app = this
      app.$store
        .dispatch("balancingGroupsGetListAction")
        .then(({ data }) => {
          app.balancing_groups_list = data.data.balancing_groups
        })
        .catch(({ response }) => {
          app.handleValidationErrors(response)
        })
    },

    saveBalancingGroup(name) {
      this.balancing_groups_list.push(name)
      this.activeItem.balancing_group = name
    },
  },
}
</script>
